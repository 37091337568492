import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { when } from 'mobx';
import { withRouter } from 'react-router-dom';
import { useTable, useFilters, useGlobalFilter } from 'react-table';
import Select from 'react-select';
import InventoryHeader from './InventoryHeader';
import Loading from '../Loading';

const flattenTableDataWithCategories = (skusByCategoryForLiveInventory) => {
    let flattenedData = [];
    for (let category in skusByCategoryForLiveInventory) {
        flattenedData.push({ isCategory: true, category });
        if (skusByCategoryForLiveInventory[category]) {
            skusByCategoryForLiveInventory[category].forEach(item => {
                flattenedData.push({ ...item, category });
            });
        }
    }
    return flattenedData;
};

const MultiSelectFilter = ({ column: { filterValue = [], setFilter, preFilteredRows, id } }) => {
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach(row => {
            if (!row.original.isCategory) {
                options.add(row.values[id]);
            }
        });
        return [...options.values()].map(option => ({ value: option, label: option }));
    }, [id, preFilteredRows]);

    const colourStyles = {
        option: () => {
          return {
            color: 'black',
            paddingTop: '10px'
          };
        },
      };

    return (
        <div style={{minWidth: '100px', maxWidth: '90%', margin: 'auto'}}>
            <Select
                isMulti
                value={filterValue.map(val => ({ value: val, label: val }))}
                options={options}
                onChange={selected => {
                    const value = selected ? selected.map(option => option.value) : [];
                    setFilter(value.length ? value : undefined);
                }}
                placeholder="Select items..."
                styles={colourStyles}
            />
        </div>
    );
};

const BooleanSelectFilter = ({ column: { filterValue, setFilter } }) => {
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined);
            }}
        >
            <option value="">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
        </select>
    );
};

const NumberRangeColumnFilter = ({ column: { filterValue = [], setFilter } }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <input
                value={(filterValue[0] || filterValue[0] == 0) ? filterValue[0] : ''}
                type="number"
                onChange={e => {
                    const val = e.target.value;
                    setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
                }}
                placeholder={`Min`}
                style={{
                    width: '70px',
                    marginRight: '5px',
                    marginLeft: '5px'
                }}
            />
            to
            <input
                value={(filterValue[1] || filterValue[1] == 0) ? filterValue[1] : ''}
                type="number"
                onChange={e => {
                    const val = e.target.value;
                    setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
                }}
                placeholder={`Max`}
                style={{
                    width: '70px',
                    marginRight: '5px',
                    marginLeft: '5px'
                }}
            />
        </div>
    );
};

const numberRangeFilterFn = (rows, id, filterValue) => {
    const [min, max] = filterValue;
    return rows.filter(row => {
        const rowValue = row.values[id];
        return (
            (min === undefined || rowValue >= min) &&
            (max === undefined || rowValue <= max)
        );
    });
};

numberRangeFilterFn.autoRemove = val => !val.length;

@inject('InventoryStore')
@observer
class LiveInventory extends Component {
    constructor(props) {
        super(props);
        this.categoryRef = [];
    }

    componentDidMount = () => {
        const {
            InventoryStore: { handleGetLiveInventoryBatches },
        } = this.props;

        handleGetLiveInventoryBatches();
        when(() => this.props.InventoryStore.totalSkus > 0);
        this.interval = setInterval(() => {
            handleGetLiveInventoryBatches();
            when(() => this.props.InventoryStore.loading === false);
        }, 1000 * 60 * 1); // 60 seconds
    };

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const {
            InventoryStore: { skusByCategoryForLiveInventory },
        } = this.props;

        if (!skusByCategoryForLiveInventory) {
            return <Loading />;
        }

        const flattenedData = flattenTableDataWithCategories(skusByCategoryForLiveInventory);

        return (
            <Fragment>
                <div style={{ maxWidth: '100%' }}>
                    <InventoryHeader title="Current Stock Dashboard" />
                    <TableComponent data={flattenedData} />
                </div>
            </Fragment>
        );
    }
}

const TableComponent = ({ data }) => {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Items',
                accessor: 'name',
                Filter: MultiSelectFilter,
                filter: 'includesSome',
            },
            {
                Header: 'AVAILABLE FOR SALE?',
                accessor: 'available_for_sale',
                Cell: ({ value }) => (<img
                    src={value ? "https://image.popcontent.link/green_checkmark.svg" : "https://image.popcontent.link/red_cross.svg"}
                    alt={value ? 'Yes' : 'No'}
                    style={{ width: '20px', height: '20px' }}
                />),
                Filter: BooleanSelectFilter,
            },
            {
                Header: 'UNSOLD STOCK',
                accessor: 'quantity_left',
                Filter: NumberRangeColumnFilter,
                filter: 'between',
            },
            {
                Header: 'PRE-ORDERS STOCK',
                accessor: 'preorders',
                Filter: NumberRangeColumnFilter,
                filter: 'between',
            },
            {
                Header: 'STOCK IN OUTLET',
                accessor: 'virtual_stock',
                Filter: NumberRangeColumnFilter,
                filter: 'between',
            },
            {
                Header: 'NOT ON HOLD?',
                accessor: 'not_on_hold',
                Cell: ({ value }) => (<img
                    src={value ? "https://image.popcontent.link/green_checkmark.svg" : "https://image.popcontent.link/red_cross.svg"}
                    alt={value ? 'Yes' : 'No'}
                    style={{ width: '20px', height: '20px' }}
                />),
                Filter: BooleanSelectFilter,
            },
            {
                Header: 'COMPONENT IN STOCK?',
                accessor: 'in_stock',
                Cell: ({ value }) => (<img
                    src={value ? "https://image.popcontent.link/green_checkmark.svg" : "https://image.popcontent.link/red_cross.svg"}
                    alt={value ? 'Yes' : 'No'}
                    style={{ width: '20px', height: '20px' }}
                />),
                Filter: BooleanSelectFilter,
            },
            {
                Header: 'IN DAILY MENU?',
                accessor: 'in_daily_menu',
                Cell: ({ value }) => (<img
                    src={value ? "https://image.popcontent.link/green_checkmark.svg" : "https://image.popcontent.link/red_cross.svg"}
                    alt={value ? 'Yes' : 'No'}
                    style={{ width: '20px', height: '20px' }}
                />),
                Filter: BooleanSelectFilter,
            },
            {
                Header: 'PRICE TABLE ON?',
                accessor: 'price_table_on',
                Cell: ({ value }) => (<img
                    src={value ? "https://image.popcontent.link/green_checkmark.svg" : "https://image.popcontent.link/red_cross.svg"}
                    alt={value ? 'Yes' : 'No'}
                    style={{ width: '20px', height: '20px' }}
                />),
                Filter: BooleanSelectFilter,
            },
            {
                Header: 'ELIGIBLE OUTLET?',
                accessor: 'eligible_outlet',
                Cell: ({ value }) => (<img
                    src={value ? "https://image.popcontent.link/green_checkmark.svg" : "https://image.popcontent.link/red_cross.svg"}
                    alt={value ? 'Yes' : 'No'}
                    style={{ width: '20px', height: '20px' }}
                />),
                Filter: BooleanSelectFilter,
            }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: () => null },
            initialState: {
                filters: [
                    {
                        id: 'eligible_outlet',
                        value: 'true'
                    }
                ]
            },
            filterTypes: {
                between: numberRangeFilterFn,
                includesSome: (rows, id, filterValue) => {
                    if (filterValue.length === 0) return rows;
                    return rows.filter(row => filterValue.includes(row.values[id]));
                },
            },
        },
        useFilters,
        useGlobalFilter 
    );

    return (
        <Fragment>
            <table {...getTableProps()} style={{ width: '100%', fontSize: '15px' }}  className="-striped">
                <thead style={{top: '48px', position: 'sticky', backgroundColor: '#303234', color: 'white', 
                        zIndex: '100', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px'}}>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} style={{padding: '10px 0'}}>
                                    {column.render('Header')}
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return row.original.isCategory ? (
                            <tr {...row.getRowProps()} style={{backgroundColor: 'rgba(246,185,78,255)', paddingTop: '15px', 
                            paddingBottom: '15px', paddingLeft: '30px', color: 'black', textTransform: 'uppercase', textAlign: "left"}}>
                                <td colSpan={columns.length} style={{padding: '7.5px'}}>
                                    <strong>{row.original.category}</strong>
                                </td>
                            </tr>
                        ) : (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()} style={{paddingTop: '7.5px'}}>{cell.render('Cell')}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Fragment>
    );
};

export default withRouter(LiveInventory);
