import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Fullscreen from 'react-full-screen';
import {Helmet} from "react-helmet";
import InstaServiceProduction from './InstaServiceProduction';
import CurrentWarmerStock from './CurrentWarmerStock';

const base = 'insta-service';
const ONE_SECOND = 1000;

@inject('OrdersKitchenStore', 'WarmerStore', 'UserStore')
@observer
class InstaService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFullscreen: false,
        };
    }

    componentDidMount() {
        const {
            OrdersKitchenStore: {
                handleGetInstaServiceProductions,
            },
            UserStore,
        } = this.props;
        const {
            selectedHub: { isOnDemandProduction },
            handleSetFullscreen
        } = UserStore;
        handleSetFullscreen(false)

        if (isOnDemandProduction) {
            handleGetInstaServiceProductions(true);
            this.interval = setInterval(() => {
                handleGetInstaServiceProductions(false);
            }, 10 * ONE_SECOND); // 10 seconds
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const { OrdersKitchenStore, UserStore } = this.props;
        const {
            isCurrentPageFullScreen: isFullscreen,
            handleSetFullscreen,
            handleExitFullscreen,
            selectedHub: { isOnDemandProduction },
        } = UserStore;
        const {
            instaServiceProductions,
            currentWarmerStocks
        } = OrdersKitchenStore;

        return (
            <Fullscreen
                enabled={isFullscreen}
                onChange={(isFullscreen) => {
                    if (isFullscreen) {
                        handleSetFullscreen(isFullscreen);
                    } else {
                        handleExitFullscreen();
                    }
                }}
            >
                <Helmet>
                    <title>InstaService | Outlet OS | Pop Meals</title>
                </Helmet>
                {isOnDemandProduction && (
                    <div className="width--100">
                        <div
                            className={`${
                                isFullscreen && 'full-screen'
                            } display--flex`}
                        >
                            {instaServiceProductions && 
                            instaServiceProductions.length > 0 && (
                                <div 
                                    className={`${
                                        isFullscreen ? 'insta-service__insta_service_production_container_full-screen' : 'insta-service__insta_service_production_container'
                                }`}>
                                    <InstaServiceProduction
                                        instaServiceProductions={instaServiceProductions}
                                    />
                                </div>
                            )}
                            
                            <div 
                            className={`${
                                isFullscreen ? 'insta-service__warmer_stock_container_full-screen' : 'insta-service__warmer_stock_container'
                            }`}>
                                <CurrentWarmerStock
                                    currentWarmerStocks={currentWarmerStocks}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </Fullscreen>
        );
    }
}

export default InstaService;
