import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import OrderRow from './OrderRow';
import Loading from '../Loading';
import { Link } from 'react-router-dom';
import moment from 'moment';

const base = 'live-dispatch';

@inject('LiveDispatchStore', 'UserStore', 'StockTransferStoreItems')
@observer
export default class Orders extends Component {
    state = {
        currentTime: null,
        statusBarColor: '#F4594F'
    };

    componentDidMount = () => {
        this.currentTime();
    };

    componentWillUnmount = () => {
        if (this.timeOut) {
            clearTimeout(this.timeOut);
        }
    };

    currentTime = () => {
        const currentTime = moment().format('LTS');
        if (moment().second() % 2 === 0){
            this.setState({statusBarColor: 'black'})
        } else {
            this.setState({statusBarColor: '#F4594F'})
        }
        this.setState({ currentTime });
        this.timeOut = setTimeout(this.currentTime, 1000);
    };

    handleClickOpenModal = () => {
        const { LiveDispatchStore } = this.props;
        LiveDispatchStore.recentlyClosedAggregatorStores = LiveDispatchStore.closedAggregatorStores;
    }

    handleClickOpenTruckModal = () => {
        const { LiveDispatchStore } = this.props;
        LiveDispatchStore.recentTruckAlerts = LiveDispatchStore.truckAlerts.filter(alert => !alert.acknowledged);
    }

    componentDidUpdate(prevProps) {
        const { lastScannedItem, LiveDispatchStore, inOutletOrders } = this.props;
        const { updateItemStatus} = LiveDispatchStore;
        if (prevProps.lastScannedItem !== lastScannedItem) {
            updateItemStatus( lastScannedItem, inOutletOrders );
        }
    }

    render() {
        const { LiveDispatchStore, UserStore, StockTransferStoreItems, inOutletOrders, routeSectionVisible, lastScannedItem } = this.props;
        const { bags, dineInOrders, loading, closedAggregatorStores, recentlyClosedAggregatorStores, truckAlerts, recentTruckAlerts } = LiveDispatchStore;
        const unacknowledgedTruckAlerts = truckAlerts.filter(alert => !alert.acknowledged);
        const { isCurrentPageFullScreen } = UserStore;
        const { receipts } = StockTransferStoreItems;

        let orders = [];
        if (inOutletOrders) {
            dineInOrders.filter((order) => (order.production_started_at && (order.sale_platform == "Pick Up" || order.sale_platform == "Dine In"))).map((dineInOrder) => (
                orders.push(dineInOrder)
            ));

            bags.filter((order) => (order.production_started_at && order.order_mode == "PICK_UP")).map((bag) => (
                orders.push(bag)
            ));
        }
        
        if (!inOutletOrders) {
            bags.filter((order) => order.production_started_at && order.order_mode != "PICK_UP").map((bag) => (
                orders.push(bag)
            ));

            dineInOrders.filter((order) => (order.production_started_at && (order.sale_platform != "Pick Up" && order.sale_platform != "Dine In"))).map((dineInOrder) => (
                orders.push(dineInOrder)
            ));
            
            orders.sort((a, b) => a.production_started_at - b.production_started_at);
        }

        if (loading) {
            return <Loading />;
        }

        return (
            <div className={`${base}__dine-in-container`}>
                <div className={`${base}__dine-in-headline`}>
                    <h1 className={`display--inline-block`} style={{width: '100%'}}>
                        {inOutletOrders && (
                            <div className={`display--flex`}>
                                <p className={`${base}__heading`}>Dine-in + Takeaway</p>

                            <p  
                            style={{
                                backgroundColor: '#F4594F',
                                borderRadius: '10px',
                                color: 'white',
                                padding: '5px',
                                fontSize: '20px',
                                height: 'fit-content',
                                margin: '15px 0px 0px 0px'
                                }}
                        >
                                PRIORITI
                            </p>
                            </div>
                        )}
                        {!inOutletOrders && (
                            <div className={`display--flex`} style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <p className={`${base}__heading`} style={{ marginRight: '10px' }}>Delivery</p>
                                    { receipts.length > 0
                                        && 
                                        <Link to="/inventory/receive-stock">
                                            <button className="fs--medium-medium-large m0 mlm" 
                                                style={{
                                                    backgroundColor: this.state.statusBarColor,
                                                    borderRadius: '20px',
                                                    color: 'white',
                                                    fontSize: '17.5px',
                                                    padding: '5px',
                                                    margin: '8px 25px 8px 0'
                                                    }}
                                            >
                                                You have a pending receipt!
                                            </button>
                                        </Link>
                                    }
                                    {recentlyClosedAggregatorStores.length === 0 && closedAggregatorStores.length > 0
                                        && 
                                        <button className="fs--medium-medium-large m0 mlm" 
                                            style={{
                                                backgroundColor: this.state.statusBarColor,
                                                borderRadius: '20px',
                                                color: 'white',
                                                fontSize: '17.5px',
                                                padding: '5px',
                                                margin: '8px 25px 8px 0'
                                                }}
                                                onClick={this.handleClickOpenModal}
                                        >
                                                ALERT {closedAggregatorStores.map(p => (<span key={p}>{p + " "}</span>))} is closed! Re-open now!
                                    </button>
                                    }
                                    {recentTruckAlerts.length === 0 && unacknowledgedTruckAlerts.length > 0
                                        && 
                                        <button className="fs--medium-medium-large m0 mlm" 
                                            style={{
                                                backgroundColor: this.state.statusBarColor,
                                                borderRadius: '20px',
                                                color: 'white',
                                                fontSize: '17.5px',
                                                padding: '5px',
                                                margin: '8px 25px 8px 0'
                                                }}
                                                onClick={this.handleClickOpenTruckModal}
                                        >
                                                ALERT {unacknowledgedTruckAlerts.map(alert => (<span key={alert.id}>{alert.status + " "}</span>))}
                                    </button>
                                    }
                                </div>
                            </div>                            
                        )}
                    </h1>
                </div>
                <div className={`${base}__dine-in-table display--flex`}
                style={ isCurrentPageFullScreen ?  
                        routeSectionVisible ? {height: '64vh'} : {height: '87vh'} :
                        routeSectionVisible ? {height: '50vh'} : {height: '77vh'}}>
                    {orders.map((order) => (
                        <OrderRow
                            key={order.order_number}
                            order={order}
                            lastScannedItem={lastScannedItem}
                        />
                    ))}                    
                </div>
            </div>
        );
    }
}
