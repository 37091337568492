import React from 'react';
import ReactDOM from 'react-dom';
import './resources/scss/main.scss';
import App from './App';
import serviceWorker from './service-worker';
import { Provider } from 'mobx-react';
import UserStore from './stores/common/UserStore';
import ScanningStore from './stores/common/ScanningStore';
import ProductionStore from './stores/ProductionStore';
import DispatchStore from './stores/DispatchStore';
import InventoryStore from './stores/InventoryStore';
import StockTransferStoreItems from './stores/StockTransferStoreItems';
import StockTransferStoreAddons from './stores/StockTransferStoreAddons';
import HubPerformanceStore from './stores/HubPerformanceStore';
import LiveDispatchStore from './stores/LiveDispatchStore';
import QueueScreenStore from './stores/QueueScreenStore';
import PickModeStore from './stores/PickModeStore';
import WarmerStore from './stores/WarmerStore';
import OrdersKitchenStore from './stores/OrdersKitchenStore';
import ComponentStockStore from './stores/ComponentStockStore';
import AttendanceStore from "./stores/AttendanceStore";
import BagScanningStore from "./stores/BagScanningStore";
import RiderAndOutletPartnerStore from './stores/RiderAndOutletPartnerStore';
import AllOrdersStore from './stores/AllOrdersStore';
import OperationDashboardStore from './stores/OperationDashboardStore';

const stores = {
    UserStore,
    ProductionStore,
    DispatchStore,
    InventoryStore,
    StockTransferStoreItems,
    StockTransferStoreAddons,
    HubPerformanceStore,
    LiveDispatchStore,
    QueueScreenStore,
    PickModeStore,
    ScanningStore,
    WarmerStore,
    OrdersKitchenStore,
    ComponentStockStore,
    AttendanceStore,
    BagScanningStore,
    RiderAndOutletPartnerStore,
    AllOrdersStore,
    OperationDashboardStore
};

ReactDOM.render(
    <Provider {...stores}>
        <App />
    </Provider>,
    document.getElementById('root')
);
serviceWorker();
