import parameters from '../configs/parameters';
import { getCity } from './cities';
import { getFromLocalStorage } from './utils';
import { version } from '../../package.json';
import Cookies from 'js-cookie';

const pickingTracking = parameters['pickingTracking'];
const url = parameters['services'];
const warmerStockPlannerConfig = parameters['warmerStockPlanner'];
const basicAuth = parameters['basicAuth'];

/**
 * Gets headers for HTTP requests against our backend
 * @param token Bearer token if required (nullable)
 * @param omitContentType If true we omit the content-type header
 * @returns {*}
 */
const getHeaders = ({
    omitContentType = false,
    tokenParam = null,
    hubCode = null,
} = {}) => {
    let token;
    let headers = {};
    if (tokenParam && hubCode) {
        // this will be enabled/used only when we will stream the screens live on TVS/displays
        token = tokenParam;
        headers['Hub-Code'] = hubCode;
        headers['City'] = getCity();
    } else {
        // this will be executed for most of the times.
        token = Cookies.get('token');
        if (getFromLocalStorage('hub')) {
            headers['Hub-Code'] = getFromLocalStorage('hub').value;
        }
        headers['City'] = getCity();
    }

    if (version) {
        headers['Version'] = version;
    }

    headers = {
        ...headers,
        'Accept-Language': 'en',
        Brand: 'dahmakan',
        // fallback to basic auth in case of missing token
        Authorization: token ? `Bearer ${token}` : `Basic ${basicAuth}`,
    };

    // in case we want javascript to decide which content-type to use
    if (!omitContentType) {
        return Object.assign({ 'Content-Type': 'application/json' }, headers);
    }
    return headers;
};

export const login = (data) =>
    fetch(`${url}/v2/oauth/token`, {
        method: 'POST',
        headers: Object.assign(getHeaders({ omitContentType: true })),
        body: data,
    })
        .then(handleErrors)
        .then((res) => res.json());

export const getDispatchListForDate = (date) =>
    fetch(`${url}/deliveries/count?date=${date}`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(handleErrors)
        .then((res) => res.json());

export const getDineInItems = (date, ifModifiedSince) => {
    const time = new Date().getTime();
    let headers = getHeaders();
    headers['Cache-Control'] = 'max-age=86400,no-cache';
    if (ifModifiedSince) {
        headers['Last-Updated-At'] = new Date(ifModifiedSince).getTime();
    }

    return fetch(
        `${url}/v7/production/dine-in-items`,
        {
            method: 'GET',
            headers: headers,
        }
    )
        .then(handleErrors)
        .then((res) => {
            if (res.status === 304) {
                // no change according to backend, just return resolved Promise
                // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Last-Modified
                // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/If-Modified-Since
                return Promise.resolve({
                    modified: false,
                });
            }

            return res.json().then((json) => {
                return {
                    modified: true,
                    lastModified: res.headers.get('last-modified'),
                    responseBody: json,
                };
            });
        });
};

export const getDeliveryItems = (date, ifModifiedSince) => {
    const time = new Date().getTime();
    let headers = getHeaders();
    headers['Cache-Control'] = 'max-age=86400,no-cache';
    if (ifModifiedSince) {
        headers['Last-Updated-At'] = new Date(ifModifiedSince).getTime();
    }

    return fetch(
        `${url}/v7/production/delivery-items`,
        {
            method: 'GET',
            headers: headers,
        }
    )
        .then(handleErrors)
        .then((res) => {
            if (res.status === 304) {
                // no change according to backend, just return resolved Promise
                return Promise.resolve({
                    modified: false,
                });
            }

            return res.json().then((json) => {
                return {
                    modified: true,
                    lastModified: res.headers.get('last-modified'),
                    responseBody: json,
                };
            });
        });
};
export const getDeliveryOrders = (date, ifModifiedSince) => {
    const time = new Date().getTime();
    let input = `${url}/v7/production/delivery-orders`;
    let headers = getHeaders();
    headers['Cache-Control'] = 'max-age=86400,no-cache';
    if (ifModifiedSince) {
        headers['Last-Updated-At'] = new Date(ifModifiedSince).getTime();
    }
    return fetch(input, {
        method: 'GET',
        headers: headers,
    })
        .then(handleErrors)
        .then((res) => res)
        .then((res) => {
            if (res.status === 304) {
                return Promise.resolve({
                    modified: false,
                });
            }

            return res.json().then((json) => {
                return {
                    modified: true,
                    lastModified: res.headers.get('last-modified'),
                    responseBody: json,
                };
            });
        });
};

export const getDineInOrders = (date, ifModifiedSince) => {
    const time = new Date().getTime();
    let input = `${url}/v7/production/dine-in-orders`;
    let headers = getHeaders();
    headers['Cache-Control'] = 'max-age=86400,no-cache';
    if (ifModifiedSince) {
        headers['Last-Updated-At'] = new Date(ifModifiedSince).getTime();
    }
    return fetch(input, {
        method: 'GET',
        headers: headers,
    })
        .then(handleErrors)
        .then((res) => res)
        .then((res) => {
            if (res.status === 304) {
                return Promise.resolve({
                    modified: false,
                });
            }

            return res.json().then((json) => {
                return {
                    modified: true,
                    lastModified: res.headers.get('last-modified'),
                    responseBody: json,
                };
            });
        });
};

export const getAggregatorStoreStatus = () => {
    const time = new Date().getTime();
    let input = `${url}/v1/admin/store/status`;
    let headers = getHeaders();
    headers['Cache-Control'] = 'max-age=86400,no-cache';
    return fetch(input, {
        method: 'GET',
        headers: headers,
    })
        .then(handleErrors)
        .then((res) => res)
        .then((res) => {
            return res.json().then((json) => {
                return json;
            });
        });
};

export const getTruckStatus = () => {
    let input = `${url}/v1/admin/truck-alerts`;
    let headers = getHeaders();
    return fetch(input, {
        method: 'GET',
        headers: headers,
    })
        .then(handleErrors)
        .then((res) => res)
        .then((res) => {
            return res.json().then((json) => {
                return json;
            });
        });
};

export const acknowledgeTruckAlert = (store) => {
    let input = `${url}/v1/admin/truck-alerts/acknowledge`;
    let headers = getHeaders();
    return fetch(input, {
        method: 'POST',
        headers: headers,
        body: store,
    })
        .then(handleErrors)
        .then((res) => res)
        .then((res) => {
            return res;
        });
};

export const openStore = (store) => {
    let input = `${url}/v1/admin/open/store`;
    let headers = getHeaders();
    return fetch(input, {
        method: 'PUT',
        headers: headers,
        body: store,
    })
        .then(handleErrors)
        .then((res) => res)
        .then((res) => {
            return res.json().then((json) => {
                return json;
            });
        });
};

export const getQueuedOrders = (date, ifModifiedSince, token, hubCode) => {
    const time = new Date().getTime();
    let input = `${url}/v7/production/queued-orders`;
    let headers = getHeaders({ tokenParam: token, hubCode });
    headers['Cache-Control'] = 'max-age=86400,no-cache';
    if (ifModifiedSince) {
        headers['Last-Updated-At'] = new Date(ifModifiedSince).getTime();
    }
    return fetch(input, {
        method: 'GET',
        headers: headers,
    })
        .then(handleErrors)
        .then((res) => res)
        .then((res) => {
            if (res.status === 304) {
                return Promise.resolve({
                    modified: false,
                });
            }

            return res.json().then((json) => {
                return {
                    modified: true,
                    lastModified: res.headers.get('last-modified'),
                    responseBody: json,
                };
            });
        });
};

export const getRoutesForDateAndDispatch = (date, dispatch) =>
    fetch(`${url}/routes/?date=${date}&dispatch_no=${dispatch}`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(handleErrors)
        .then((res) => res.json());

export const sendRoute = (routeID) => {
    let input = `${url}/routes/third-party/delivery/${routeID}`;
    let headers = getHeaders();

    return fetch(input, {
        method: 'POST',
        headers: headers,
    })
        .then(handleErrors)
        .then((res) => {
            return res.json().then((json) => {
                return {
                    modified: true,
                    lastModified: res.headers.get('last-modified'),
                    responseBody: json,
                };
            });
        });
};

export const sendRetryDelivery = (orderId) => {
    let input = `${url}/routes/retry/third-party/delivery/${orderId}`;
    let headers = getHeaders();

    return fetch(input, {
        method: 'POST',
        headers: headers,
    })
        .then(handleErrors)
        .then((res) => {
            return res.json().then((json) => {
                return {
                    modified: true,
                    lastModified: res.headers.get('last-modified'),
                    responseBody: json,
                };
            });
        });
};

export const getRoutes = (date, ifModifiedSince) => {
    let input = `${url}/v7/production/live-dispatch`;
    let headers = getHeaders();
    headers['Cache-Control'] = 'max-age=86400,no-cache';
    if (ifModifiedSince) {
        headers['Last-Updated-At'] = new Date(ifModifiedSince).getTime();
    }
    return fetch(input, {
        method: 'GET',
        headers: headers,
    })
        .then(handleErrors)
        .then((res) => res)
        .then((res) => {
            if (res.status === 304) {
                // no change according to backend, just return resolved Promise
                // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Last-Modified
                // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/If-Modified-Since
                return Promise.resolve({
                    modified: false,
                });
            }

            return res.json().then((json) => {
                return {
                    modified: true,
                    lastModified: res.headers.get('last-modified'),
                    responseBody: json,
                };
            });
        });
};

export const getInstaServiceProduction = (ifModifiedSince) => {
    const time = new Date().getTime();
    let input = `${url}/v7/production/warmer`;
    let headers = getHeaders();
    headers['Cache-Control'] = 'max-age=86400,no-cache';
    if (ifModifiedSince) {
        headers['Last-Updated-At'] = new Date(ifModifiedSince).getTime();
    }
    return fetch(input, {
        method: 'GET',
        headers: headers,
    })
        .then(handleErrors)
        .then((res) => res)
        .then((res) => {
            if (res.status === 304) {
                // no change according to backend, just return resolved Promise
                // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Last-Modified
                // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/If-Modified-Since
                return Promise.resolve({
                    modified: false,
                });
            }

            return res.json().then((json) => {
                return {
                    modified: true,
                    lastModified: res.headers.get('last-modified'),
                    responseBody: json,
                };
            });
        });
};

export const markOrderAsDone = (orderId) => {
    let success;
    let input = `${url}/v7/production/mark/done/${orderId}`;
    let headers = getHeaders();

    return fetch(input, {
        method: 'POST',
        headers: headers,
    })
        .then((response) => {
            if (response.ok) {
                success = true;
            }
            return response.json();
        })
        .then((data) => {
            if (!success) {
                throw Error(data.message);
            }
            return data;
        });
};

export const getCashierScreenResponse = (date, ifModifiedSince) => {
    const time = new Date().getTime();
    let input = `${url}/v7/production/cashier`;
    let headers = getHeaders();
    headers['Cache-Control'] = 'max-age=86400,no-cache';
    if (ifModifiedSince) {
        headers['Last-Updated-At'] = new Date(ifModifiedSince).getTime();
    }
    return fetch(input, {
        method: 'GET',
        headers: headers,
    })
        .then(handleErrors)
        .then((res) => res)
        .then((res) => {
            if (res.status === 304) {
                // no change according to backend, just return resolved Promise
                // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Last-Modified
                // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/If-Modified-Since
                return Promise.resolve({
                    modified: false,
                });
            }

            return res.json().then((json) => {
                return {
                    modified: true,
                    lastModified: res.headers.get('last-modified'),
                    responseBody: json,
                };
            });
        });
};

export const confirmPickupOrder = (deliveryId) =>
    fetch(
        `${url}/v7/production/mark-customer-self-pickup-complete/${deliveryId}`,
        {
            method: 'POST',
            headers: getHeaders(),
        }
    )
        .then(handleErrors)
        .then((res) => res.json());

export const confirmDineInOrder = (deliveryId) =>
    fetch(`${url}/v1/direct/dine-in/${deliveryId}/complete`, {
        method: 'POST',
        headers: getHeaders(),
    })
        .then(handleErrors)
        .then((res) => res.json());

export const getChefNames = (data) =>
    fetch(`${url}/v1/menus/additional-menu-information`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(data),
    })
        .then(handleErrors)
        .then((res) => res.json());

export const getHubs = () =>
    fetch(`${url}/v5/rider/hubs`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(handleErrors)
        .then((res) => res.json());

export const getProductionBatches = (date, item_type) =>
    fetch(
        `${url}/v4/production/batches-on-date?date=${date}&item_type=${item_type}`,
        {
            method: 'GET',
            headers: getHeaders(),
        }
    )
        .then(handleErrors)
        .then((res) => res.json());

export const getLiveInventoryBatches = (date, item_types) =>
    fetch(
        `${url}/v4/production/live-inventory-batches-on-date?date=${date}&item_types=${item_types}`,
        {
            method: 'GET',
            headers: getHeaders(),
        }
    )
        .then(handleErrors)
        .then((res) => res.json());

export const getVirtualStock = (menuId, id) =>
    fetch(`${url}/v4/production/batches/virtual-stock/${menuId}/${id}`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(handleErrors)
        .then((res) => res.json());

export const setActualStock = (item) => {
    let success;

    return fetch(`${url}/v1/menus/batch/stock-check/${item.menuId}`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(item),
    })
        .then((response) => {
            if (response.ok) {
                success = true;
            }
            return response.json();
        })
        .then((data) => {
            if (!success) {
                throw Error(data.message);
            }
            return data;
        });
};

export const setActualQuickStock = (item) =>
    fetch(`${url}/v1/menus/batch/quick-stock-check/${item.menuId}`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(item),
    })
        .then(handleErrors)
        .then((res) => res.json());

export const getHubStockTransfers = (date) =>
    fetch(`${url}/v1/admin/transfer-plans?date=${date}`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(handleErrors)
        .then((res) => res.json());

export const validateTransferPlan = (transferPlan) =>
    fetch(`${url}/v1/admin/transfer-plan/validate/${transferPlan.id}`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(transferPlan),
    })
        .then(handleErrors)
        .then((res) => res.json());

export const updateTransferPlan = (transferPlan) =>
    fetch(`${url}/v1/admin/transfer-plan/${transferPlan.id}`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(transferPlan),
    })
        .then(handleErrors)
        .then((res) => res.json());

export const executeTransferPlan = (id) =>
    fetch(`${url}/v1/admin/transfer-plan/execute?csvPlanIds=${id}`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(handleErrors)
        .then((res) => res);

export const getOdooReceipts = (hubCode) =>
    fetch(`${url}/v1/odoo/receipts?hubCode=${hubCode}`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(handleErrors)
        .then((res) => res.json());

export const getOdooReceiptProducts = (receipt_id) =>
    fetch(`${url}/v1/odoo/receipt-products?id=${receipt_id}`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(handleErrors)
        .then((res) => res.json());


export const updateOdooReceiptProducts = (productValues, receipt_id, hub_code, errorCallback) =>
    fetch(`${url}/v1/odoo/receipt-products?receiptId=${receipt_id}&hubCode=${hub_code}`, {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify(productValues),
    })
        .then((response) => handleErrors(response, errorCallback))
        .then((res) => res);

export const getHubStockTransfersAddons = () =>
    fetch(
        `${url}/v1/menus/menu-items?filter=Addons&order=asc&showUnavailableItems=false&sortby=name`,
        {
            method: 'GET',
            headers: getHeaders(),
        }
    )
        .then(handleErrors)
        .then((res) => res.json());

export const updateTransferPlanAddons = (transferPlan) =>
    fetch(`${url}/v1/menus/batch/${transferPlan.menu_item_id}`, {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify(transferPlan),
    })
        .then(handleErrors)
        .then((res) => res.json());

export const updateSKUSaleAvailability = (body) =>
    fetch(`${url}/v1/menus/menu-item/available-for-sale/`, {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .then((res) => res.json());

export const getSKUSalesAvailability = (date, itemType) =>
    fetch(
        `${url}/v1/menus/available-for-sale?date=${date}&item_type=${itemType}`,
        {
            method: 'GET',
            headers: getHeaders(),
        }
    )
        .then(handleErrors)
        .then((res) => res.json());

export const getComponentForCurrentMenuItemSku = (sku) =>
    fetch(`${url}/v1/menus/menu-item/component/${sku}`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(handleErrors)
        .then((res) => res.json());

export const updatePickingData = (
    hub,
    cycleDate,
    cycle,
    mealsCount,
    startTime,
    endTime
) => {
    fetch(
        `${pickingTracking}?hub=${hub}&date=${cycleDate}&cycle=R${cycle}&mealsCount=${mealsCount}&startTime=${startTime}&endTime=${endTime}`,
        {
            method: 'GET',
        }
    )
        .then(handleErrors)
        .then((res) => res.json());
};

export const handledScannedItem = (
    todayMenuId,
    date,
    incrementalNumber,
    pickUpNumber,
    errorCallback
) => {
    let input = `${url}/v7/production/produced-item/${todayMenuId}?date=${date}&incrementalNumber=${incrementalNumber}`;
    if (pickUpNumber) input += `&pickUpNumber=${pickUpNumber}`;

    return fetch(input, {
        method: 'POST',
        headers: getHeaders(),
    })
        .then((response) => handleErrors(response, errorCallback))
        .then((res) => res.json());
};

export const handledScannedWarmerItem = (
    productionId,
    sku,
    quantity,
    errorCallback
) => {
    let input = `${url}/v7/production/scan/warmer`;
    const data = {
        production_id: productionId,
        sku: sku,
        quantity: quantity
    };

    return fetch(input, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(data),
    })
        .then((response) => handleErrors(response, errorCallback))
        .then((res) => res.json());
};

export const handledScannedAttendance = (code) => {
    let success;
    const time = new Date().getTime();
    let input = `${url}/v1/users/attendance/record?time=${time}`;
    const data = { code };

    return fetch(input, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (response.ok) {
                success = true;
            }
            return response.json();
        })
        .then((data) => {
            if (!success) {
                throw Error(data.message);
            }
            return data;
        });
};

export const uploadItemImage = (image, item) => {
    let input = `${url}/v7/production/upload-meal-image`;
    const data = {
        image,
        item,
    };
    return fetch(input, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(data),
    })
        .then(handleErrors)
        .then((res) => {
            return res;
        });
};

export const handledScannedBagSticker = (order_id, image) => {
    let input = `${url}/v7/production/scan/bag`;
    const data = {
        order_id,
        image,
    };

    return fetch(input, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(data),
    })
        .then(handleErrors)
        .then((res) => res.json());
};

export const handleRemakeSKUItem = (
    { incremental_number, item_type, order_number, sku, today_menu_id } = item,
    errorCallback
) => {
    let input = `${url}/v7/production/replace`;
    const data = {
        incremental_number,
        item_type,
        order_number,
        sku,
        today_menu_id,
    };
    return fetch(input, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(data),
    })
        .then((response) => handleErrors(response, errorCallback))
        .then((res) => res.json());
};

export const handleReprintSKUItem = (
    { incremental_number, item_type, order_number, sku, today_menu_id } = item,
    errorCallback
) => {
    let input = `${url}/v7/production/print-item-sticker`;
    const data = {
        incremental_number,
        item_type,
        order_number,
        sku,
        today_menu_id,
    };
    return fetch(input, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(data),
    })
        .then((response) => handleErrors(response, errorCallback))
        .then((res) => { return res;});
};


export const printLabel = (printing_string, printer_id) => {
    let input = `${url}/v7/production/print`;
    const data = {
        printing_string,
        printer_id,
    };
    return fetch(input, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(data),
    })
        .then(handleErrors)
        .then((res) => {
            return res;
        });
};

export const sendLogToBackend = (logString) => {
    const input = `${url}/v1/admin/log`;
    const data = {
        log: logString
    }
    return fetch(input, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(data),
    });

};

function handleErrors(response, errorCallback) {
    // 200 or 304 are both not errors for us

    if (!response.ok && response.status !== 304) {
        let error = new Error(response.statusText);
        error.response = response;
        if (errorCallback) {
            errorCallback(error);
        }
        throw Error(error);
    }
    return response;
}

export const getWarmerStockLevel = (hubCode, dateTimeToPlan) => {
    let input = `${warmerStockPlannerConfig.url}`;
    const data = {
        date_time_to_plan: dateTimeToPlan,
        hub_code: hubCode,
    };
    return fetch(input, {
        method: 'POST',
        headers: {
            'x-api-key': warmerStockPlannerConfig.token,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then(handleErrors)
        .then((res) => {
            return res.json();
        });
};

export const saveComponentStock = (data) => {
    let success;
    let input = `${url}/v1/admin/inventory/units`;
    let headers = getHeaders();
    return fetch(input, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (response.ok) {
                success = true;
            }
            return response.json();
        })
        .then((data) => {
            if (!success) {
                throw Error(data.message);
            }
            return data;
        });
};

export const getUnitSkus = () =>
    fetch(`${url}/v1/admin/inventory/units/skus`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(handleErrors)
        .then((res) => res.json());

export const getStockCountList = () =>
    fetch(`${url}/v1/admin/inventory/stock/count/list`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(handleErrors)
        .then((res) => res.json());

export const getUnitBatches = () =>
    fetch(`${url}/v1/admin/inventory/units/batches`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(handleErrors)
        .then((res) => res.json());

export const getUnitBatchesHistory = () =>
    fetch(`${url}/v1/admin/inventory/units/batches/history`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(handleErrors)
        .then((res) => res.json());

export const updateUnitBatch = (data) => {
    let success;
    let input = `${url}/v1/admin/inventory/units`;
    let headers = getHeaders();
    return fetch(input, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (response.ok) {
                success = true;
            }
            return response.json();
        })
        .then((data) => {
            if (!success) {
                throw Error(data.message);
            }
            return data;
        });
};

export const updateUnitBatches = (data) => {
    let input = `${url}/v1/admin/inventory/units/menu-item-update`;
    let headers = getHeaders();
    return fetch(input, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(data),
    });
};

export const getLiveInventoryOverview = () =>
    fetch(`${url}/v1/admin/inventory/overview`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(handleErrors)
        .then((res) => res.json());

export const sendPrintRequest = (request) => {
    let input = `${url}/v7/production/print-sticker`;
    return fetch(input, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(request),
    })
        .then(handleErrors)
        .then((res) => {
            return res;
        });
};

export const getActiveRiders = () => {
    const time = new Date().getTime();
    let headers = getHeaders();
    headers['Cache-Control'] = 'max-age=86400,no-cache';
    return fetch(`${url}/v1/rider/status`, {
        method: 'GET',
        headers: headers,
    })
        .then(handleErrors)
        .then((res) => res.json());
};

export const getActiveOutletPartners = () => {
    const time = new Date().getTime();
    let headers = getHeaders();
    headers['Cache-Control'] = 'max-age=86400,no-cache';
    return fetch(`${url}/v1/users/clocked-in/outlet-partner`, {
        method: 'GET',
        headers: headers,
    })
        .then(handleErrors)
        .then((res) => res.json());
};

export const getActiveStaffMembers = () => {
    const time = new Date().getTime();
    let headers = getHeaders();
    headers['Cache-Control'] = 'max-age=86400,no-cache';
    return fetch(`${url}/v1/users/staff-members`, {
        method: 'GET',
        headers: headers,
    })
        .then(handleErrors)
        .then((res) => res.json());
};

export const getInCartOrders = () => {
    return fetch(`${url}/v2/pos/in-cart/orders`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(handleErrors)
        .then((res) => res.json());
};

export const getAllOrders = (today) => {
    return fetch(`${url}/v1/orders/v3/all-orders?searchbyenddate=${today}&searchbystartdate=${today}`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(handleErrors)
        .then((res) => res.json());
};

export const getAreaByOutletData = () => {
    let input = `${url}/v1/admin/outlet-area-data`;
    let headers = getHeaders();
    return fetch(input, {
        method: 'GET',
        headers: headers,
    })
        .then(handleErrors)
        .then((res) => res.json());
};


export const getOperationDashboardInfo = (outlets) => {
    let input = `${url}/v1/admin/operation-dashboard`;
    let headers = getHeaders();
    return fetch(input, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(outlets)
    })
        .then(handleErrors)
        .then((res) => res.json());
};