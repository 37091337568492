import { observable, action } from 'mobx';
import Cookies from 'js-cookie';
import * as api from '../../services/api';
import { saveToLocalStorage, getFromLocalStorage } from '../../services/utils';

const { BrowserPrint } = window;

class UserStore {
    @observable authedUser = null;
    @observable loading = false;
    @observable hubsList = [];
    @observable selectedHub = getFromLocalStorage('hub') || '';
    @observable pages = [
        'inventory',
        'packing',
        'cooking',
        'insta-service',
        'attendance-scan',
        'all-orders',
        'operation-dashboard'
    ];
    @observable currentPage = this.pages[0];
    @observable message = '';
    @observable isPrinterConnected =
        getFromLocalStorage('printerStatus') || false;
    @observable isCurrentPageFullScreen = false;

    @action checkPrinterStatus = () => {
        BrowserPrint.getLocalDevices(
            (device_list) => {
                //Add device to list of devices and to html select element
                if (
                    device_list.length ||
                    this.selectedHub.kitchenPrinterId ||
                    this.selectedHub.dispatchPrinterId
                ) {
                    this.setPrinterStatus(true);
                } else {
                    this.setPrinterStatus(false);
                }
            },
            undefined,
            'printer'
        );
    };

    @action handleSetFullscreen = (fullScreenState) => {
        if (fullScreenState) {
            this.isCurrentPageFullScreen = fullScreenState;
        } else {
            this.isCurrentPageFullScreen = !this.isCurrentPageFullScreen;
        }
    };

    @action handleExitFullscreen = () => {
        this.isCurrentPageFullScreen = false;
    };

    setPrinterStatus = (status) => {
        saveToLocalStorage('printerStatus', status);
        this.isPrinterConnected = status;
    };

    @action login = (data) => {
        this.loading = true;
        api.login(data)
            .then((res) => {
                Cookies.set('token', res.access_token, { expires: 30 });
                this.authedUser = res.access_token;
            })
            .catch((err) => {
                this.message = err.message;
            })
            .finally(() => (this.loading = false));
    };

    @action logout = () => {
        Cookies.remove('token');
        localStorage.clear();
        window.location.replace('/');
    };

    @action handleGetHubs = () => {
        this.loading = true;
        let hubsList = [];
        api.getHubs()
            .then((res) => {
                if (res.length > 0) {
                    res.forEach((r) => {
                        const name = r.pickup_address && r.pickup_address.name
                        const hubsObj = {
                            value: r.code,
                            label: name,
                            city: r.city,
                            kitchenPrinterId: r.kitchen_printer_id,
                            dispatchPrinterId: r.dispatch_printer_id,
                            isOnDemandProduction: true,
                            pickupAddress: r.pickup_address,
                        };
                        hubsList.push(hubsObj);
                    });
                }
                this.hubsList = hubsList;
                saveToLocalStorage('hubsList', hubsList);
            })
            .catch((err) => {
                this.message = err.message;
            })
            .finally(() => (this.loading = false));
    };

    @action setSelectedHub = (hub) => {
        this.selectedHub = hub;
        if (
            this.selectedHub.kitchenPrinterId ||
            this.selectedHub.dispatchPrinterId
        ) {
            this.setPrinterStatus(true);
        }
        saveToLocalStorage('hub', hub);
    };

    @action setPage = (page) => {
        this.currentPage = page;
    };

    @action setMessage = (message) => {
        this.message = message;
    };
}

const store = new UserStore();
export default store;
